<template>
  <div id="actionDialog">
    <el-dialog
        title="车位分组"
        :visible.sync="show"
        :close-on-press-escape="false"
        @closed="reset"
        @open="getCompanyList"
    >
      <el-form
          :model="actionForm"
          :rules="ruleForm"
          ref="actionForm"
          label-width="80px"
      >
        <el-form-item label="分组名称" prop="seatGroupName">
          <el-input v-model="actionForm.seatGroupName" placeholder="请输入分组名称"></el-input>
        </el-form-item>
        <el-form-item label="车位数量" prop="quantityLimit">
          <el-input v-model="actionForm.quantityLimit" placeholder="请输入最大车位数量"></el-input>
        </el-form-item>
        <el-form-item label="收费入场" prop="chargeAdmission">
          <el-switch
              v-model="actionForm.chargeAdmission">
          </el-switch>
        </el-form-item>
        <el-form-item label="归属方">
          <el-radio-group v-model="attributableParty">
            <el-radio label="个人"></el-radio>
            <el-radio label="公司"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="所属个人" v-if="attributableParty==='个人'">
          <el-input v-model="actionForm.owner" placeholder="请输入所属个人"></el-input>
        </el-form-item>
        <el-form-item label="所属公司" v-if="attributableParty==='公司'">
          <el-select v-model="actionForm.companyId" placeholder="请选择所属公司">
            <el-option v-for="item of companyList" :key="item.issuerId" :label="item.merchantName"
                       :value="item.issuerId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input v-model="actionForm.contacts" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="actionForm.contactPhone" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="actionForm.remarks"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
            type="primary"
            size="medium"
            @click="confirmBtn"
            :loading="loading"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getInvoice", "parkingLotId"],
  data() {
    return {
      show: false,
      loading: false,
      seatGroupId: "",
      attributableParty: "",
      companyList: [],
      actionForm: {
        seatGroupName: "",
        quantityLimit: "",
        chargeAdmission: false,
        owner: "",
        companyId: "",
        contacts: "",
        contactPhone: "",
        remarks: "",
      },
      ruleForm: {
        chargeAdmission: [
          {required: true, message: '请选择收费入场', trigger: 'change'}
        ],
        seatGroupName: [
          {required: true, message: '请输入分组名称', trigger: 'blur'},
        ],
        quantityLimit: [
          {required: true, message: '请输入最大车位数量', trigger: 'blur'},
        ],
      },
    };
  },
  methods: {
    async getCompanyList() {
      let res = await this.$http.post("/statistics/merchant/list", {
        parkingLotId: this.$route.params.id
      })
      this.companyList = res.data
    },
    reset() {
      this.seatGroupId = ""
      this.attributableParty = ""
      this.companyList = []
      this.$set(this, "actionForm", {
        seatGroupName: "",
        quantityLimit: "",
        chargeAdmission: false,
        owner: "",
        companyId: "",
        contacts: "",
        contactPhone: "",
        remarks: "",
      });
      this.$refs["actionForm"].resetFields();
    },
    confirmBtn() {
      this.$refs["actionForm"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;

            if (this.attributableParty === '个人') {
              this.actionForm.companyId = null
            } else if (this.attributableParty === '公司') {
              this.actionForm.owner = null
            } else {
              this.actionForm.companyId = null
              this.actionForm.owner = null
            }

            let url = "/parking/seat/group/add"
            let params = {
              ...this.actionForm,
              parkingLotId: this.$route.params.id,
              chargeAdmission: this.actionForm.chargeAdmission ? 1 : 0
            }
            if (this.seatGroupId) {
              url = "/parking/seat/group/edit"
              delete params.parkingLotId
              params.seatGroupId = this.seatGroupId
            }

            let res = await this.$http.post(url, params);
            if (res.code === 0) {
              this.$message.success("操作成功");
              this.$emit("refreshList")
              this.show = false;
            } else {
              this.$message.error(res.msg);
            }
            this.loading = false;
          } catch (err) {
            this.$message.error(err);
            this.loading = false;
          }
        }
      });

    },
  },
};
</script>
<style lang="scss">
#actionDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;

      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);

        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;

          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }

        .el-dialog__headerbtn {
          position: initial;

          i {
            color: black;
          }
        }
      }

      .el-dialog__body {
        padding: 15px;

        .el-select {
          width: 100%;
        }
      }

      .el-dialog__footer {
        text-align: center;

        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
